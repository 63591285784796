
.sliders {
  display: flex;
  height: 200px;
  padding: 10px;
  margin-top: 70px;
  margin-left: 24px;
}

.slider-item {
  width: 42.2px;
  position: relative;
}

.slider-item-input {
  transform-origin: 75px 75px;
  transform: rotate(-90deg);
  width: 200px;
}

.slider-item-fields {
  position: absolute;
  width: 30px;
  bottom: 20px;
  left: -10px;
  text-align: center;
  font-size: 0.8em;
}

.slider-item-enabled {
  margin-left: 13px;
  margin-bottom: 5px;
  display: block;
  transform: scale(1.4);
}

.slider-item-number {
  margin-top: 5px;
}