.options {
  display: flex;
  padding: 10px 0;
  text-align: right;
  font-weight: bold;
  font-size: 1.1em;
  justify-content: space-between;
  border-bottom: solid 1px #333;
}

.options-controls {
  display: flex;
}

.options-style {
  display: block;
  margin-bottom: 5px;
}

.options-header {
  text-align: left;
  font-size: 1.2em;
  font-weight: bold;
  padding: 0 5px;
  border-bottom: solid 1px #333;
  margin: 10px 0;
}

.options-controls button {
  color: #3479F6;
  padding: 6px 15px;
  border: solid 1px #3479F6;
  font-weight: bold;
  font-size: 0.9em;
  border-radius: 5px;
  margin: 5px 5px 5px 0;
  background-color: transparent;
  cursor: pointer;
}

.reset-button {
  background-color: transparent;
  border: none;
  font-size: 0.9em;
  color: #3479F6;
  cursor: pointer;
}

.options-controls button.selected {
  background-color: #3479F6;
  color: #fff;
}