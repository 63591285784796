.App {
  text-align: center;
  width: 1000px;
  margin: 0 200px 0 50px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.header {
  font-size: 1.7em;
  font-weight: bold;
  margin-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

.header img {
  margin-right: 30px;
}

