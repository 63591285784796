
.summary-container {
  position: relative;
}

.summary {
  position: absolute;
  top: 0;
  right: -350px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.summary-outcome {
  font-size: 1.2em;
  padding: 10px;
  margin-bottom: 10px;
  margin-right: 5px;
  border: solid 1px #979797;
  max-width: 305px;
  border-radius: 5px;
  color: #979797;
}

.summary-rows {
  display: flex;
}

.summary-row {
  display: flex;
  margin-bottom: 1px;
}

.summary-row-icon {
  width: 70px;
  margin-right: 10px;
}

.summary-row-title {
  font-size: 1.2em;
  font-weight: bold;
  text-align: left;
  background-color: #979797;
  color: #fff;
  border-radius: 3px;
  text-align: center;
  padding: 10px;
  margin-right: 5px;
}

.summary-items {
  list-style: none;
  padding: 0;
}

.summary-items > li {
  border: solid 1px #979797;
  padding: 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  height: 60px;
  border-radius: 3px;
  min-width: 139px;
}

.summary-item-title {
  color: #979797;
  text-align: left;
}

.summary-item-text {
  display: flex;
  gap: 3px;
}

.summary-item-value {
  font-size: 2.4em;
}

.summary-item-details {
  font-size: 0.9em;
  display: flex;
  flex-direction: column;
  margin: 7px 0;
  text-align: left;
}

.summary-item-units {
  color: #979797;
  margin-top: auto;
}

.summary-item-compare {
  font-weight: bold;
}

.summary-items > li.summary-item-good {
  border-color: green;
}

.summary-items > li.summary-item-bad {
  border-color: red;
}

.summary-item-good .summary-item-compare {
  color: green
}

.summary-item-bad .summary-item-compare {
  color: red
}

@media (max-width: 1550px) {
  .summary-items > li {
    display: inline-block;
  }

  .summary {
    position: static;
    padding-bottom: 10px;
    display: block;
  }

  .summary-outcome {
    max-width: inherit;
  }
}